<template>
  <v-toolbar-items align="right">
    <v-dialog
        v-model="showDatePicker"
        ref="dialog"
        width="290px"
    >
      <v-date-picker
          v-model="date"
          @change="showDatePicker=false"
      />
    </v-dialog>
    <v-btn @click="showDatePicker=true">
      {{ dateFormatted }}
    </v-btn>
    <v-btn :to="{ params: { date: `${dayBefore}`}}" icon
    >
      <v-icon>mdi-skip-previous</v-icon>
    </v-btn>
    <v-btn :to="{ params: { date: `${dayAfter}`}}" icon>
      <v-icon>mdi-skip-next</v-icon>
    </v-btn>
  </v-toolbar-items>
</template>

<script>
import moment from 'moment';
import {mapActions} from 'vuex';

export default {
  name: "MealButtons",
  data() {
    return {
      showDatePicker: false
    }
  },
  methods: {
    ...mapActions(['setDate'])
  },
  computed: {
    date: {
      get() {
        return this.$route.params.date;
      },
      set(val) {
        const newDate = moment(val).format('YYYY-MM-DD');
        const newLocation = {name: this.$route.name, params: {date: newDate}};
        console.log('new ', newLocation);
        this.setDate(newDate);
        this.$router.push(newLocation);
      }
    },
    dateFormatted() {
      return moment(this.$route.params.date).format('dddd MMM DD');
    },
    dayBefore() {
      return moment(this.$route.params.date).subtract(1, 'day').format('YYYY-MM-DD');
    },
    dayAfter() {
      return moment(this.$route.params.date).add(1, 'day').format('YYYY-MM-DD');
    },
  }
}
</script>

<style scoped>

</style>
